@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/inter/inter-400.ee408088.woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/inter/inter-500.3d2a2311.woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/inter/inter-600.10c6bcb8.woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/inter/inter-700.c6caa55f.woff2");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/nunito-sans/NunitoSans-400.22f3de35.ttf");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/nunito-sans/NunitoSans-500.79a2f114.ttf");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/nunito-sans/NunitoSans-600.010c50bb.ttf");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/nunito-sans/NunitoSans-700.67f249c2.ttf");
}

:root {
  --basic-black: #1e1e1;
  --basic-white: #fff;
  --basic-brand: #ffd300;
  --font-primary: #1e1e1e;
  --color-background-primary: #f3f5fc;
  --font-family-text-primary: "Inter", serif;
  --font-family-text-head: "Nunito Sans", sans-serif;
  --color-scrollbar-thumb: var(--black-100);
  --color-scrollbar-track: var(--black-50);
  --transition-duration: .15s;
  --container-width: 1440px;
  --container-padding: 16px;
  --header-height: 60px;
}

@media (width >= 1280px) {
  :root {
    --container-padding: 40px;
    --header-height: 108px;
  }
}

html {
  box-sizing: border-box;
}

header, main {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

footer {
  width: 100%;
  margin: 0 auto;
}

main {
  padding-left: 40px;
  padding-right: 40px;
}

*, :before, :after {
  box-sizing: inherit;
}

a[href^="mailto:"], a[href^="tel:"] {
  white-space: nowrap;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.visually-hidden {
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.container {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.scroll-lock {
  overflow: hidden;
}

@media (width <= 601px) {
  main {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.header {
  z-index: 5;
  height: 124px;
}

.header-block {
  padding: 24px 40px 40px;
}

.header-block__elem {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.header-block__elem-menu {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
  display: flex;
}

.header-block__elem-menu-logo-wrapper {
  align-items: center;
  display: flex;
}

.header-block__elem-menu-fixed {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
  display: flex;
}

.header-block__elem-menu-logo {
  object-fit: contain;
  width: 182px;
  height: 40px;
}

.header-block__elem-menu-link {
  height: inherit;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  display: flex;
}

.header-block__elem-menu-link a {
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  font-family: var(--font-family-text-head);
  text-decoration: none;
  transition: color .3s;
}

.header-block__elem-menu-link a:hover {
  color: #ffd737;
}

.header-block-contacts {
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  display: flex;
}

.header-block-contacts__tel {
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s;
}

--black.header-block-contacts__tel {
  color: #1f1f1f;
}

--white.header-block-contacts__tel {
  color: #fff;
}

.header-block-contacts__number {
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 700;
  transition: color .3s;
}

.header-block-contacts__tel:hover, .header-block__elem-menu-link--black:hover, .header-block-contacts__tel--black:hover, .header-block-contacts__number:hover {
  color: #ffd737;
}

.header-block-contacts__flat {
  cursor: pointer;
  color: #1e1e1e;
  background: #ffd737;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.fixed-header {
  z-index: 99;
  color: #1e1e1e;
  background: #f3f5fb;
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 16px 32px;
  animation: .5s forwards slideDown;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 5px 5px #0000001a;
}

.fixed-header--hide {
  display: none;
}

.header-block-white {
  color: #fff;
}

.header-block-black {
  color: #1e1e1e;
}

.header-block-contacts__tel--white {
  color: #fff;
  transition: color .3s;
}

.header-block__elem-menu-link--black {
  color: #1e1e1e;
  transition: color .3s;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.header-block__elem-menu-mobile {
  display: none;
}

.header-modal-menu {
  z-index: 999;
  background-color: #f3f5fb;
  width: 100%;
  height: 100%;
  padding: 32px;
  animation: .5s forwards slideDown;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 4px 8px #0000001a;
}

.header-modal-menu-contain {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.header-modal-menu .header-modal-menu__head a {
  align-items: center;
  display: flex;
}

.header-modal-menu__head {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  display: flex;
}

.header-modal-menu__head-button-span {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 700;
}

.show-modal {
  display: block;
}

.header-modal-menu__head-button {
  background: var(--basic-brand);
  border-radius: 50px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: auto;
  padding: 16px;
  transition: background .3s;
  display: flex;
}

.close-btn:hover {
  background: var(--basic-brand);
}

.modal-menu__links {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 24px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.header-block__elem-menu-link--black-list, .modal-menu__links-el {
  color: #1e1e1e;
  font-weight: 700;
  text-decoration: none;
}

.footer-menu-list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 24px;
  display: flex;
}

.footer-menu-list__link {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
}

.header-modal-menu__number {
  text-align: center;
  z-index: 99;
  color: var(--basic-brand);
  margin: auto;
  font-size: 42px;
  font-weight: 700;
  text-decoration: none;
}

@media (width <= 1350px) {
  .header-block__elem-menu-logo {
    width: 164px;
    height: 36px;
  }

  .header-block__elem-menu-link a {
    font-size: 14px;
  }

  .header-block-contacts__flat {
    width: auto;
    padding: 24px;
  }

  .header-block-contacts__tel, .header-block-contacts__tel--black, .header-block-contacts__number {
    display: none;
  }
}

@media (width <= 1100px) {
  .header-block__elem-menu-link {
    display: none;
  }

  .header-block__elem-menu-mobile {
    display: block;
  }

  .header-block-contacts__flat {
    display: none;
  }

  .header-block__elem-menu-mobile-contain {
    cursor: pointer;
    background: var(--basic-brand);
    color: #1e1e1e;
    border-radius: 100px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 22px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
  }

  .header-block__elem-menu, .header-block__elem-menu-fixed {
    align-items: center;
    gap: 0;
  }
}

@media (width <= 601px) {
  .header {
    height: 100px;
  }

  .header-block {
    padding: 16px 16px 18px;
  }

  .fixed-header {
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    padding: 16px 16px 18px;
  }

  .header-modal-menu {
    padding: 16px;
  }

  .modal-menu__links {
    margin: 16px;
  }

  .footer-menu-list {
    margin: 16px;
    padding-top: 16px;
  }

  .header-modal-menu__number {
    font-size: 24px;
    bottom: 5%;
  }
}

@media (width <= 430px) {
  .close-btn {
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
  }
}

.footer {
  width: 100%;
  height: 753px;
  position: relative;
}

.footer-info__contacts {
  background: linear-gradient(var(--color-background-primary), #0000);
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 100%;
  padding-top: 50px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-info__contacts-tel {
  color: var(--basic-black);
  font-size: 52px;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s;
}

.footer-info__contacts-tel:hover {
  color: var(--basic-brand);
}

.footer-info__menu-logo-tel-number {
  transition: color .3s;
}

.footer-info__menu-logo-tel-number:hover {
  color: var(--basic-brand);
}

.footer-info__contacts-buttons {
  box-sizing: border-box;
  background: var(--basic-white);
  border-radius: 50px;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;
  width: 482px;
  height: 47px;
  padding: 4px;
  display: flex;
}

.footer-info__menu {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 40px 30px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-info__menu-logo {
  align-items: center;
  gap: 100px;
  display: flex;
}

.footer-info__menu-logo-img {
  object-fit: contain;
  width: 182px;
  height: 40px;
}

.footer-info__menu-rasa {
  color: #1e1e1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.footer-info__menu-rasa--color {
  color: #3daeff;
}

.footer-info__menu-logo-tel {
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;
  display: flex;
}

.footer-info__menu-logo-tel a {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--font-family-text-head);
  text-decoration: none;
  transition: color .3s;
}

.footer-block_menu-link {
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  height: 44px;
  display: flex;
}

.footer-block_menu-link a {
  cursor: pointer;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--font-family-text-head);
  text-decoration: none;
  transition: color .3s;
}

.footer-block_menu-link a:hover {
  color: #ffd737;
}

.footer-info__contacts-button {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.footer-info__contacts-button-input {
  display: none;
}

.footer-info__contacts-button-name {
  text-align: center;
  border-radius: 100px;
  width: 100%;
  padding: 10px;
  display: inline-block;
}

.footer-info__contacts-button-input:checked + .footer-info__contacts-button-name {
  background: #ffd500;
  transition: background-color .3s ease-out;
}

.show {
  display: block;
}

@media (width <= 1250px) {
  .footer-info__menu-logo {
    gap: 24px;
  }

  .footer-info__menu-logo-img {
    width: 164px;
    height: 36px;
  }

  .footer-block_menu-link {
    display: none;
  }
}

@media (width <= 901px) {
  .footer {
    height: 547px;
  }

  .footer-info__contacts {
    padding-top: 60px;
  }
}

@media (width <= 601px) {
  .footer-info__contacts-tel {
    font-size: 32px;
  }

  .footer-info__contacts-buttons {
    width: 345px;
  }

  .footer-info__menu-logo-tel {
    display: none;
  }

  .footer-info__menu {
    padding: 0 16px 30px;
  }
}

@media (width <= 355px) {
  .footer-info__contacts-buttons {
    width: 90vw;
  }
}

.base {
  font-family: var(--font-family-text-primary);
  color: var(--color-text-primary);
  background-color: var(--color-background-primary);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  overflow-x: hidden;
}

.base__body {
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow-x: clip;
}

.base__body #bx-panel {
  display: none !important;
}

@media (width >= 1024px) {
  .base__body #bx-panel {
    display: block !important;
  }
}
/*# sourceMappingURL=index.3a632003.css.map */
