.header {
    height: 124px;
    z-index: 5;
}

.header-block {
    padding: 24px 40px 40px;
}

.header-block__elem {
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.header-block__elem-menu {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 36px;
}

.header-block__elem-menu-logo-wrapper {
    display: flex;
    align-items: center;
}

.header-block__elem-menu-fixed {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 36px;
}

.header-block__elem-menu-logo {
    height: 40px;
    width: 182px;
    object-fit: contain;
}

.header-block__elem-menu-link {
    height: inherit;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;
}

.header-block__elem-menu-link a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
    font-size: 15px;
    transition: color 0.3s;
    font-family: var(--font-family-text-head);
}

.header-block__elem-menu-link a:hover {
    color: #ffd737;
}

.header-block-contacts {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
}

.header-block-contacts__tel {
    text-decoration: none;
    font-weight: 700;
    font-size: 15px;
    transition: color 0.3s;

    &--black {
        color: #1f1f1f;
    }

    &--white {
        color: #ffffff;
    }
}

.header-block-contacts__number {
    color: #1e1e1e;
    font-size: 15px;
    font-weight: 700;
    transition: color 0.3s;
}

.header-block-contacts__tel:hover,
.header-block__elem-menu-link--black:hover,
.header-block-contacts__tel--black:hover,
.header-block-contacts__number:hover {
    color: #ffd737;
}

.header-block-contacts__flat {
    cursor: pointer;
    width: 260px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    color: #1e1e1e;
    background: #ffd737;
    border-radius: 100px;
    border: none;
    text-decoration: none;
    transition: background-color 0.3s;
}

.fixed-header {
    display: block;
    max-width: 1440px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 16px 32px;
    z-index: 99;
    animation: slideDown 0.5s forwards;
    background: #f3f5fb;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    overflow: hidden;
    color: #1e1e1e;
}

.fixed-header--hide {
    display: none;
}

.header-block-white {
    color: #ffffff;
}

.header-block-black {
    color: #1e1e1e;
}

.header-block-contacts__tel--white {
    color: #ffffff;
    transition: color 0.3s;
}

.header-block__elem-menu-link--black {
    color: #1e1e1e;
    transition: color 0.3s;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.header-block__elem-menu-mobile {
    display: none;
}

/*Меню*/
.header-modal-menu {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    background-color: #f3f5fb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.5s forwards;
    z-index: 999;
}

.header-modal-menu-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.header-modal-menu .header-modal-menu__head a {
    display: flex;
    align-items: center;
}

.header-modal-menu__head {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
}

.header-modal-menu__head-button-span {
    font-size: 16px;
    font-weight: 700;
    color: #1e1e1e;
}

.show-modal {
    display: block;
}

.header-modal-menu__head-button {
    width: auto;
    padding: 16px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 50px;
    background: var(--basic-brand);
    transition: background 0.3s;
}

.close-btn:hover {
    background: var(--basic-brand);
}

.modal-menu__links {
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    font-size: 24px;
    font-weight: 700;
}

.header-block__elem-menu-link--black-list {
    text-decoration: none;
    color: #1e1e1e;
    font-weight: 700;
}

.modal-menu__links-el {
    text-decoration: none;
    color: #1e1e1e;
    font-weight: 700;
}

.footer-menu-list {
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.footer-menu-list__link {
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    color: #1e1e1e;
}

.header-modal-menu__number {
    text-decoration: none;
    margin: auto;
    text-align: center;
    z-index: 99;
    color: var(--basic-brand);
    font-size: 42px;
    font-weight: 700;
}

/*Адаптив*/
@media (max-width: 1350px) {
    .header-block__elem-menu-logo {
        height: 36px;
        width: 164px;
    }

    .header-block__elem-menu-link a {
        font-size: 14px;
    }

    .header-block-contacts__flat {
        width: auto;
        padding: 24px;
    }
    .header-block-contacts__tel,
    .header-block-contacts__tel--black,
    .header-block-contacts__number {
        display: none;
    }
}

@media (max-width: 1100px) {
    .header-block__elem-menu-link {
        display: none;
    }

    .header-block__elem-menu-mobile {
        display: block;
    }

    .header-block-contacts__flat {
        display: none;
    }

    .header-block__elem-menu-mobile-contain {
        cursor: pointer;
        padding: 16px 22px;
        background: var(--basic-brand);
        border-radius: 100px;
        display: flex;
        flex-wrap: nowrap;
        gap: 8px;
        align-items: center;
        justify-content: center;
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 700;
    }

    .header-block__elem-menu,
    .header-block__elem-menu-fixed {
        gap: 0;
        align-items: center;
    }
}

@media (max-width: 601px) {
    .header {
        height: 100px;
    }

    .header-block {
        padding: 16px 16px 18px 16px;
    }

    .fixed-header {
        border-bottom-left-radius: 45px;
        border-bottom-right-radius: 45px;
        padding: 16px 16px 18px 16px;
    }

    .header-modal-menu {
        padding: 16px;
    }

    .modal-menu__links {
        margin: 16px;
    }

    .footer-menu-list {
        margin: 16px;
        padding-top: 16px;
    }

    .header-modal-menu__number {
        font-size: 24px;
        bottom: 5%;
    }
}

@media (max-width: 430px) {
    .close-btn {
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
    }
}
