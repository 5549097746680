@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter/inter-400.woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/inter/inter-500.woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/inter/inter-600.woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/inter/inter-700.woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/nunito-sans/NunitoSans-400.ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/nunito-sans/NunitoSans-500.ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/nunito-sans/NunitoSans-600.ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/nunito-sans/NunitoSans-700.ttf');
}
