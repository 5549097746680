.base {
    font-family: var(--font-family-text-primary);
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: var(--color-text-primary);
    background-color: var(--color-background-primary);
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.base__body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    min-height: 100vh;
    overflow-x: clip;

    #bx-panel {
        display: none !important;

        @media (min-width: 1024px) {
            display: block !important;
        }
    }
}
