.footer {
    position: relative;
    width: 100%;
    height: 753px;
}

.footer-info__contacts {
    padding-top: 50px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    width: 100%;
    background: linear-gradient(var(--color-background-primary), rgba(0, 0, 0, 0));
}

.footer-info__contacts-tel {
    text-decoration: none;
    font-size: 52px;
    font-weight: 700;
    color: var(--basic-black);
    transition: color 0.3s;
}

.footer-info__contacts-tel:hover {
    color: var(--basic-brand);
}

.footer-info__menu-logo-tel-number {
    transition: color 0.3s;
}

.footer-info__menu-logo-tel-number:hover {
    color: var(--basic-brand);
}

.footer-info__contacts-buttons {
    padding: 4px;
    box-sizing: border-box;
    width: 482px;
    height: 47px;
    background: var(--basic-white);
    border-radius: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 4px;
}

.footer-info__menu {
    padding: 0 40px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-info__menu-logo {
    display: flex;
    align-items: center;
    gap: 100px;
}

.footer-info__menu-logo-img {
    height: 40px;
    width: 182px;
    object-fit: contain;
}

.footer-info__menu-rasa {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    color: #1e1e1e;
}

.footer-info__menu-rasa--color {
    color: #3daeff;
}

.footer-info__menu-logo-tel {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 24px;
}

.footer-info__menu-logo-tel a {
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    color: #2a2a2a;
    transition: color 0.3s;
    font-family: var(--font-family-text-head);
}

.footer-block_menu-link {
    height: 44px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;
}

.footer-block_menu-link a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    color: #2a2a2a;
    transition: color 0.3s;
    font-family: var(--font-family-text-head);
}

.footer-block_menu-link a:hover {
    color: #ffd737;
}

.footer-info__contacts-button {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.footer-info__contacts-button-input {
    display: none;
}

.footer-info__contacts-button-name {
    padding: 10px;
    width: 100%;
    border-radius: 100px;
    display: inline-block;
    text-align: center;
}

.footer-info__contacts-button-input:checked + .footer-info__contacts-button-name {
    background: rgb(255, 213, 0);
    transition: background-color 0.3s ease-out;
}

.show {
    display: block;
}

@media (max-width: 1250px) {
    .footer-info__menu-logo {
        gap: 24px;
    }

    .footer-info__menu-logo-img {
        width: 164px;
        height: 36px;
    }

    .footer-block_menu-link {
        display: none;
    }
}

@media (max-width: 901px) {
    .footer {
        height: 547px;
    }

    .footer-info__contacts {
        padding-top: 60px;
    }
}

@media (max-width: 601px) {
    .footer-info__contacts-tel {
        font-size: 32px;
    }

    .footer-info__contacts-buttons {
        width: 345px;
    }

    .footer-info__menu-logo-tel {
        display: none;
    }

    .footer-info__menu {
        padding: 0 16px 30px;
    }
}

@media (max-width: 355px) {
    .footer-info__contacts-buttons {
        width: 90vw;
    }
}
