:root {
    --transition-duration: 0.15s;

    --container-width: 1440px;
    --container-padding: 16px;

    --header-height: 60px;

    @media (min-width: 1280px) {
        --container-padding: 40px;

        --header-height: 108px;
    }
}
