html {
    box-sizing: border-box;
}

header,
main {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

footer {
    width: 100%;
    margin: 0 auto;
}

main {
    padding-left: 40px;
    padding-right: 40px;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a[href^='mailto:'],
a[href^='tel:'] {
    text-decoration: none;
    white-space: nowrap;

    /*@media (hover: hover) {*/
    /*    & {*/
    /*        transition: color var(--transition-duration);*/
    /*    }*/

    /*    &:hover {*/
    /*        color: var(--color-text-primary-hover);*/
    /*    }*/
    /*}*/
}

button {
    cursor: pointer;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip-path: inset(50%);
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
}

.container {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.scroll-lock {
    overflow: hidden;
}

@media (max-width: 601px) {
    main {
        padding-left: 16px;
        padding-right: 16px;
    }
}
