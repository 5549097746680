:root {
    --basic-black: #1e1e1;
    --basic-white: #ffffff;
    --basic-brand: #ffd300;
    --font-primary: #1e1e1e;

    --color-background-primary: #f3f5fc;

    --font-family-text-primary: 'Inter', serif;
    --font-family-text-head: 'Nunito Sans', sans-serif;

    --color-scrollbar-thumb: var(--black-100);
    --color-scrollbar-track: var(--black-50);
}
